<template>
    <header>
        <div class="header" :class="{ 'header-gap': hasArrow }">
            <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                <img src="../assets/img/arrow-back.svg" alt="refresh-image" />
            </a>
            <a id="logo" href="/carregamento">
                <img src="../assets/img/jau-logo.png" alt="logo-supermercado" />
            </a>
            <p>{{ titulo }}</p>
            <a :class="{ 'refresh-none': hasNoRefresh }" href="#">
                <img src="../assets/img/refresh.svg" alt="refresh-image" />
            </a>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        titulo: {
            required: true,
            type: String,
        },
        hasArrow: {
            type: Boolean,
        },
        hasNoRefresh: {
            type: Boolean,
        },
    },
};
</script>

<style scoped>
header {
    width: 100%;
    display: flex;
    min-height: 56px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--cinza);
}

.header button a {
    background-color: #fff;
}

.header button {
    cursor: pointer;
    border: none;
}

.header button:hover {
    position: relative;
    top: 5px;
}

.refresh-none {
    display: none;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
}
</style>
