<template>
    <header v-if="title !== 'semHeader'">
        <div class="header">
            <a @click="drawerVisible = true">
                <img src="./assets/img/menu-black.svg" alt="refresh-image" />
            </a>
            <transition name="fade">
                <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                    <img src="./assets/img/arrow-back.svg" alt="refresh-image" />
                </a>
            </transition>

            <p>
                <strong>{{ title }}</strong>
            </p>
            <p>Loja {{ getLojaUsuario }}</p>
            <button id="btnRefresh" :class="{ 'refresh-none': hasNoRefresh }" type="button" @click="sincronizaDados">
                <img src="./assets/img/refresh.svg" alt="refresh-image" />
                <span id="attNumber" v-if="getNumerosSync > 0">{{ getNumerosSync }}</span>
            </button>
        </div>
    </header>

    <transition name="slide" mode="out-in">
        <template v-if="drawerVisible">
            <div>
                <div class="left-drawer" :style="{ width: drawerVisible ? '65%' : '0' }">
                    <div class="containerMenuDrawer">
                        <a @click="drawerVisible = false">
                            <img src="./assets/img/close-white.svg" alt="refresh-image" />
                        </a>
                        <h1 class="title-menu">Menu</h1>
                    </div>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'alert' ? 'activeRoute' : 'inactiveRoute'"
                        :to="{ name: 'alert' }"
                    >
                        <img :src="getImg('alert', 'file-text')" alt="tela tarefas" />
                        <h1 :class="this.$route.name == 'alert' ? 'activeText' : 'inactiveText'">Alertas</h1>
                        <div class="all-tarefas">{{ getTotalTarefas }}</div>
                    </router-link>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'produtos' ? 'activeRoute' : 'inactiveRoute'"
                        :to="{ name: 'produtos' }"
                    >
                        <img :src="getImg('produtos', 'folder-plus')" alt="tela produtos" />
                        <h1 :class="this.$route.name == 'produtos' ? 'activeText' : 'inactiveText'">Inclusão</h1>

                        <div class="all-tarefas">{{ getTotalInclusao }}</div>
                    </router-link>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'pesaveis' ? 'activeRoute' : 'inactiveRoute'"
                        :to="{ name: 'pesaveis' }"
                        v-if="getUsuario()"
                    >
                        <img :src="getImg('pesaveis', 'scale')" alt="tela pesáveis" />
                        <h1 :class="this.$route.name == 'pesaveis' ? 'activeText' : 'inactiveText'">Pesáveis</h1>
                        <div class="all-tarefas">{{ getTotalPesaveis }}</div>
                    </router-link>
                    <router-link @click="drawerVisible = false" class="btnSair" :to="{ name: 'login' }">
                        <h1>Sair</h1>
                    </router-link>
                </div>
            </div>
        </template>
    </transition>

    <template v-if="isLogin">
        <header-principal :hasNoRefresh="true" titulo="Login"></header-principal>
    </template>

    <transition name="fade">
        <template v-if="alertSync">
            <div class="container-modal-sync">
                <div class="container-alerta-sync">
                    <div @click="this.alertSync = false">
                        <img src="./assets/img/close-black.svg" />
                    </div>
                    <h1 class="text-alerta-sync">
                        <p style="font-weight: bold">Atenção:</p>
                        Há muitas tarefas sem sincronizar. Procure um local com boa internet e sincronize as tarefas
                        executadas.
                    </h1>
                    <button type="button" @click="sincronizaDados">Sincronizar agora</button>
                </div>
            </div>
        </template>
    </transition>

    <mensagem-alerta @closeModal="closeModal" :mensagem="mensagem"></mensagem-alerta>

    <router-view v-slot="slotProps">
        <transition name="slide" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>

    <tela-sync v-if="sincronizing" @closePopUp="closePopUp" :msgSync="msgSync"></tela-sync>

    <!-- <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <router-link
                class="format-footer validade"
                :class="[{ 'footer-branco': isPlanilha }, { 'footer-branco': isProduto }]"
                :to="{ name: 'alert' }"
            >
                <img v-if="isAlerta" src="./assets/img/clock.svg" alt="relogio" />
                <img v-else src="./assets/img/clock-grey.svg" alt="relogio" />
                <h3>Alertas de Validade</h3>
            </router-link>
            <router-link
                class="format-footer inclusao"
                :class="[
                    { 'footer-branco': isAlerta },
                    { 'footer-branco': isProduto },
                    { 'footer-active': isPlanilha },
                ]"
                :to="{ name: 'planilha.inicial' }"
            >
                <img v-if="isPlanilha" src="./assets/img/clipboard-white.svg" alt="relogio" />
                <img v-else src="./assets/img/clipboard.svg" alt="relogio" />
                <h3>Tarefas Recomendadas</h3>
            </router-link>
            <router-link
                class="format-footer inclusao"
                :class="[
                    { 'footer-branco': isAlerta },
                    { 'footer-branco': isPlanilha },
                    { 'footer-active': isProduto },
                ]"
                :to="{ name: 'produtos' }"
            >
                <img v-if="isProduto" src="./assets/img/add-white.svg" alt="relogio" />
                <img v-else src="./assets/img/add.svg" alt="relogio" />
                <h3>Inclusão Manual</h3>
            </router-link>
        </footer>
    </template> -->

    <div class="btn-save" v-if="isLogin">
        <button class="salvar" type="submit" form="formLogin">Entrar</button>
    </div>
</template>

<script>
import HTTP from './http/http.js';
import HeaderPrincipal from './components/HeaderPrincipal';
import MensagemAlerta from './components/MensagemAlerta.vue';
import TelaSync from './components/TelaSync.vue';
import amplitude from 'amplitude-js';

export default {
    components: {
        MensagemAlerta,
        HeaderPrincipal,
        TelaSync,
    },
    data() {
        return {
            title: 'Valid - Jau',
            drawerVisible: false,
            hasMenu: false,
            hasArrow: false,
            hasNoRefresh: false,
            isProduto: false,
            isAlerta: true,
            isPlanilha: false,
            isSave: false,
            mensagem: {},
            isLogin: false,
            sincronizing: false,
            counter: 0,
            msgSync: 'Sincronizando... Por favor, aguarde.',
            alertSync: false,
        };
    },

    computed: {
        getNumerosSync() {
            return this.$store.getters.getNumerosSync;
        },
        getLojaUsuario() {
            return this.$store.getters.getLojaUsuario;
        },
        getTotalTarefas() {
            return this.$store.getters.getTotalTarefas;
        },
        getTotalPesaveis() {
            return this.$store.getters.getTotalTarefasPesaveis;
        },
        getTotalInclusao() {
            return this.$store.getters.getTotalTarefaInclusao;
        },
    },
    methods: {
        getUsuario() {
            let pesaveis = sessionStorage.getItem('usuarioPesaveis');
            if (pesaveis == 1) {
                return true;
            } else {
                return false;
            }
        },

        getImg(tela, nomeImg) {
            let nomeTela = this.$route.name;
            let resp = '';
            if (nomeImg) resp = nomeImg;
            let isBlack = true;
            if (tela === nomeTela) isBlack = false;
            return resp === '' ? '' : require(`./assets/img/${resp}${isBlack ? '-black' : ''}.svg`);
        },

        sincronizaDados() {
            if (navigator.onLine) {
                amplitude.getInstance().init('c510f4279cddb8f43efb1611d445555c');
                let event = 'Botão Sincronizar';
                amplitude.getInstance().logEvent(event);

                this.sincronizing = true;

                this.enviaInclusao();

                this.enviaEditInclusao();

                this.enviaTarefas();

                this.enviaTarefasPlanilha();

                this.deleteTarefasPlanilha();

                setTimeout(() => {
                    this.sincronizing = false;

                    if (this.counter > 0) {
                        this.mensagem = {
                            isSend: true,
                            body: `${this.counter} sincronizações geraram erro. Certifique sua internet e sincronize novamente. `,
                            error: true,
                        };
                    } else {
                        this.mensagem = {
                            isSend: true,
                            body: 'Dados sincronizados com sucesso!',
                            error: false,
                        };
                    }

                    setTimeout(() => {
                        this.mensagem.isSend = false;
                        this.counter = 0;
                    }, 3000);
                }, 4000);
            } else {
                this.mensagem = {
                    isSend: true,
                    body: 'Você precisa estar conectado a internet.',
                    error: true,
                };
            }
        },

        enviaInclusao() {
            let produtosSalvos = JSON.parse(localStorage.getItem('inclusao'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusao');

            let produtosNaoEnviados = [];

            if (produtosSalvos) {
                produtosSalvos.forEach((data) => {
                    HTTP.post(`/jau/${loja}/tarefas-inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusao', dataToLS);
                        });
                });
            }
        },

        enviaEditInclusao() {
            let produtosEditados = JSON.parse(localStorage.getItem('inclusaoEdit'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusaoEdit');

            let produtosNaoEnviados = [];

            if (produtosEditados) {
                produtosEditados.forEach((data) => {
                    HTTP.patch(`/jau/${loja}/tarefas-inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusaoEdit', dataToLS);
                        });
                });
            }
        },

        enviaTarefas() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('alertasValidade'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('alertasValidade');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`/jau/${loja}/tarefas/multiplas`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('alertasValidade', dataToLS);
                        });
                });
            }
        },

        enviaTarefasPlanilha() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('tarefasPlanilha'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('tarefasPlanilha');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`/jau/${loja}/tarefas-planilha`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('tarefasPlanilha', dataToLS);
                        });
                });
            }
        },

        deleteTarefasPlanilha() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('deletePlanilha'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('deletePlanilha');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.delete(`/jau/${loja}/tarefas-planilha`, { data: data })
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('deletePlanilha', dataToLS);
                        });
                });
            }
        },

        closePopUp() {
            this.sincronizing = false;
        },

        closeModal() {
            this.mensagem.isSend = false;
        },
    },
    watch: {
        $route: function (val) {
            this.title = val.meta.title;
            this.hasArrow = val.meta.hasArrow;
            this.hasNoRefresh = val.meta.hasNoRefresh;
            this.isProduto = val.meta.isProduto;
            this.isAlerta = val.meta.isAlerta;
            this.isPlanilha = val.meta.isPlanilha;
            this.isSave = val.meta.isSave;
            this.isLogin = val.meta.isLogin;
            this.hasMenu = val.meta.hasMenu;
        },
        getNumerosSync(newValue) {
            if (newValue >= 10) {
                this.alertSync = true;
            } else {
                this.alertSync = false;
            }
        },
    },
};
</script>

<style>
:root {
    --vermelho: #214f87;
    --vermelho-claro: #1f94d1;
    --vermelho-escuro: #153d6f;
    --branco: #ffffff;
    --preto: #000;
    --cinza: #cdcdcd;
    --cinza-escuro: #5f6368;
}

* {
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    width: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
}

.left-drawer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    height: 100vh;
    border-right: 1px solid #cdcdcd;
    background: #fff;
    z-index: 200;
}
.containerMenuDrawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15%;
    width: 100%;
    padding: 10px 10px;
    /* box-shadow: 0 3px 2px #fff; */
    border-bottom: 1px solid #fff;
    background-color: #0f1628;
}

.containerMenuDrawer a {
    display: flex;
    align-items: center;
}

.title-menu {
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.activeRoute {
    background-color: #56b88f;
}

.inactiveRoute {
    background-color: #f1f1f1;
}

.activeText {
    color: #fff;
}

.inactiveText {
    color: #000;
}

.btnRouter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid #fff;
}

.btnRouter h1 {
    font-size: 20px;
    padding-left: 10px;
    text-align: center;
}

.btnSair {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: #ef6969;
}

.all-tarefas {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    min-width: 30px;
    background-color: #eee;
    border: 1px solid #214f87;
    border-radius: 100px;
    border: 1px solid #000;
    padding: 5px;
    font-size: 14px;
    color: #000;
}

.btnSair h1 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100vw);
}

.slide-enter-to,
.slide-leave-from {
    transform: translateX(0px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-both-enter-from {
    transform: translateX(-100vw);
}

.slide-both-leave-to {
    transform: translateX(100vw);
}

.slide-both-enter-to,
.slide-both-leave-from {
    transform: translateX(0px);
}

.slide-both-enter-active,
.slide-both-leave-active {
    transition: all 0.4s ease-in-out;
}

header {
    width: 100%;
    display: flex;
    min-height: 56px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;
    border-bottom: 1px solid var(--cinza);
    gap: 3px;
}

.header p {
    font-size: 16px;
}

.header button a {
    background-color: #fff;
}

.container-modal-sync {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-alerta-sync {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
}

.container-alerta-sync h1 {
    text-align: center;
    font-size: 16px;
}

.container-alerta-sync button {
    background-color: #214f87;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 5px;
    font-size: 16px;
}

.container-alerta-sync div {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;
    border-radius: 5px;
    padding: 2px;
}

#logo {
    /* display: flex; */
    display: none;
}

#logo img {
    width: 40px;
}

.header a img {
    width: 25px;
}

.header button {
    cursor: pointer;
    border: none;
}

.header button:hover {
    position: relative;
    top: 5px;
}

.header p strong {
    font-size: 15px;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    background-color: var(--vermelho);
    box-shadow: 0 0 5px #cdcdcd;
    gap: 1px;
    height: 80px;
}

footer a img {
    width: 18px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer h3 {
    font-size: 15px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
    text-align: center;
}

.inclusao h3 {
    color: #4e4e4e;
}

.footer-active h3 {
    color: var(--branco);
}

.footer-branco {
    background-color: #fff;
    color: #4e4e4e;
}

.footer-branco h3 {
    color: #4e4e4e;
    text-align: center;
}

#btnRefresh {
    background-color: transparent;
    display: flex;
}

#btnRefresh img {
    width: 25px;
}

#btnRefresh.refresh-none {
    display: none;
}

#attNumber {
    border-radius: 30%;
    position: absolute;
    bottom: 3px;
    font-size: 10px;
    right: 3px;
    background-color: var(--vermelho);
    color: #fff;
    padding: 3px;
}

.btn-save {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #cdcdcd;
    justify-content: flex-end;
}

.salvar {
    cursor: pointer;
    min-width: 64px;
    border: none;
    padding: 20px 45px;
    background-color: #fff;
    color: var(--vermelho);
    font-weight: 600;
    font-size: 20px;
}

.salvar:hover {
    box-shadow: none;
    background-color: var(--vermelho);
    color: #fff;
}

@media (max-width: 321px) {
    .format-footer h3 {
        font-size: 10px;
    }

    footer a img {
        width: 16px;
    }

    .format-footer {
        padding: 8px;
    }

    header {
        min-height: 45px;
    }

    .format-footer h3 {
        font-size: 13px;
    }

    .header p {
        font-size: 14px;
    }

    .header a img {
        width: 22px;
    }

    #btnRefresh img {
        width: 22px;
    }
}

@media (min-width: 600px) {
    .header p {
        font-size: 1.3rem;
    }

    .salvar {
        font-size: 1.5rem;
    }

    .header p strong {
        font-size: 20px;
    }

    #btnRefresh img {
        width: 30px;
    }

    .header a img {
        width: 30px;
    }

    .format-footer h3 {
        font-size: 19px;
    }
}
</style>
