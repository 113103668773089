<template>
    <transition name="fade">
        <div class="popUp" v-if="mensagem.isSend">
            <div class="tela-mensagem">
                <div class="error" v-if="mensagem.error">
                    <span class="error-icon">x</span>
                </div>
                <div class="sucess" v-else>
                    <img src="../assets/img/check-white.svg" alt="imagem de check" />
                </div>
                <div class="sucess-msg">
                    {{ mensagem.body }}
                    <button @click="closeModal" type="button">Ok!</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        mensagem: {
            required: true,
            type: Object,
        },
    },

    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style scoped>
.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 15%;
    bottom: 25%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 6px;
}

.error {
    padding: 10px 20px;
    text-align: center;
    background-color: #ef6969;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-icon {
    font-size: 80px;
}

.sucess {
    background-color: #64b058;
    padding: 10px 20px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sucess img {
    width: 100px;
}

.sucess-msg {
    padding: 10px;
    height: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.sucess-msg button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.sucess-msg button:hover {
    position: relative;
    top: 5px;
}
</style>
